import apiService from "./apiService";

interface LoginData {
  email: string;
  password: string;
}

interface ChangePasswordData {
  email: string;
  password: string;
  key: string;
}

interface EditStepData {
  email: string;
  name?: string;
  emailchange?: string;
}

interface DeleteData {
  email: string;
}

// interface LogoutData {
//   email: string;
// }

interface ForgotPasswordData {
  email: string;
}

export const loginUser = async (data: LoginData) => {
  return apiService.post("/login", data);
};

export const changeUserPassword = async (data: ChangePasswordData) => {
  return apiService.post("/patchUser", data);
};

export const editUser = async (data: EditStepData) => {
  return apiService.post("/patchUser", data);
};

export const deleteUser = async (data: DeleteData) => {
  console.warn("Unsupported", data);
  // return apiService.post("/user/delete", data);
};

export const logoutUser = async () => {
  // Delete token from store
  sessionStorage.removeItem("user");
  // return apiService.post("/user/logout", data);
};

export const forgotPassword = async (data: ForgotPasswordData) => {
  console.warn("Unsupported", data);
  // return apiService.post("/user/forgot-password", data);
};

export const verifyUserToken = async (email: string) => {
  return apiService.post("/verify", { email });
};

export const refreshUserToken = async (user: any) => {
  user.token = user.refresh;
  sessionStorage.setItem("user", JSON.stringify(user));
  const response = await apiService.post("/refresh", { email: user.email });
  user.token = response.data.token;
  user.refresh = response.data.refresh;
  sessionStorage.setItem("user", JSON.stringify(user));
  return response;
};

export const getUser = async (email: string) => {
  return apiService.post("/user", { email });
};
