// LOGIN
export const AUTH_LOGIN_HEADER = "Login";
export const AUTH_LOGIN_DESCRIPTION = "Enter your email & password to login";
export const AUTH_LOGIN_BUTTON = "Login";

export const CONTACT_US_BUTTON = "Contact Us";

export const AUTH_CHANGE_PASSWORD_BUTTON = "Change Password";
export const AUTH_UPDATE_PROFILE_BUTTON = "Update Profile";
export const AUTH_DELETE_ACCOUNT_BUTTON = "Delete Account";
