import { createContext, useContext } from "react";
import { Socket } from "socket.io-client";

export type CustomApiWebSocket = Socket<
  ApiWebSocket.ListenEvents,
  ApiWebSocket.EmitEvents
>;

export interface ApiWebSocketCtxState {
  socket: CustomApiWebSocket;
}

export const ApiWebSocketCtx = createContext<ApiWebSocketCtxState>(
  {} as ApiWebSocketCtxState
);

export const useApiWebSocketCtx = () => useContext(ApiWebSocketCtx);
