import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import { RunReportForm } from "@/components/forms/RunReportForm";
import useSourcesStore from "@/stores/sources.store";
import { useParams } from "react-router-dom";

function RunReport() {
  const { source } = useParams();

  const sourceType = useSourcesStore(
    (state) =>
      state.sourceGroups.find(
        (sourceGroup) => sourceGroup.sourceGroupId === source
      )?.groupType
  );

  return (
    <DashboardMainCard header="Run Report" description="Run a new report">
      <RunReportForm
        sourceGroupIds={source ? [source] : []}
        sourceType={sourceType}
      />
    </DashboardMainCard>
  );
}

export default RunReport;
