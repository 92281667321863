import { ChangePasswordSchema, ChangePasswordType } from "@/zod/auth.zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { AUTH_CHANGE_PASSWORD_BUTTON } from "@/config/auth";
import { AlertDestructive } from "@/components/alerts/AlertDestructive";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { LOADING_TEXT } from "@/config/general";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useToast } from "../ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import useAuthStore from "@/stores/auth.store";
import { changeUserPassword } from "@/services/userService";

export const ChangePasswordForm = () => {
  const user = useAuthStore((state) => state.user);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { toast } = useToast();

  const changePasswordForm = useForm<ChangePasswordType>({
    resolver: zodResolver(ChangePasswordSchema),
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data: ChangePasswordType) => {
    try {
      setLoading(true);

      if (data.newPassword !== data.confirmPassword) {
        setError("Passwords do not match");
        return;
      }

      const payload: any = {
        email: user?.email,
        password: data.newPassword,
      };
      await changeUserPassword(payload);

      // TODO: Logout user after password change

      toast({
        title: "Success",
        description: "Password changed successfully",
      });
    } catch (error: any) {
      console.warn(error);
      setError(error.errors[0]);

      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to change password.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...changePasswordForm}>
      <div className="mt-4">
        {error ? <AlertDestructive title="Error" description={error} /> : <></>}
      </div>
      <form
        onSubmit={changePasswordForm.handleSubmit(onSubmit)}
        className="space-y-8"
      >
        <FormField
          control={changePasswordForm.control}
          name="newPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Enter your new Password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={changePasswordForm.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm New Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Enter confirm your new password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {loading ? (
          <Button disabled size="lg">
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
            {LOADING_TEXT}
          </Button>
        ) : (
          <Button type="submit" size="lg">
            {AUTH_CHANGE_PASSWORD_BUTTON}
          </Button>
        )}
      </form>
    </Form>
  );
};
