import { COMPANY_NAME } from "@/config/general";

export const CopyrightBanner = () => {
  return (
    <small className="w-full text-center my-2">
      &copy; Copyright <span className="font-bold">{COMPANY_NAME}</span>. All
      Rights Reserved
    </small>
  );
};
