import { ASSETS } from "@/config/general";
import { animated, useTransition } from "@react-spring/web";
import { Hourglass } from "lucide-react";
import React, { useMemo, useState } from "react";
import useMeasure from "react-use-measure";

type Asset = {
  label: string;
  name: string;
  icon: string;
  category: string;
  isActive: boolean;
};

const getUniqueCategories = (assets: Asset[]) => {
  const categories = assets
    .map((asset) => asset.category)
    .sort((a, b) => a.localeCompare(b));
  return ["All", ...Array.from(new Set(categories))];
};

const CARD_HEIGHT = 135;
const HORIZONTAL_MARGIN = 10; // Horizontal margin between cards

const NetworkGrid: React.FC = () => {
  const [filter, setFilter] = useState<string>("All");
  const [ref, { width }] = useMeasure();

  const allNetworks = ASSETS.sort((a, b) => a.label.localeCompare(b.label));

  const filteredNetworks =
    filter === "All"
      ? allNetworks
      : allNetworks.filter((network) => network.category === filter);

  const columns = Math.min(
    4,
    Math.floor((width + HORIZONTAL_MARGIN) / (150 + HORIZONTAL_MARGIN))
  );
  const cardWidth = (width - HORIZONTAL_MARGIN * (columns - 1)) / columns; // Calculate card width dynamically

  const [heights, gridItems] = useMemo(() => {
    let heights = new Array(columns).fill(0);
    let gridItems = filteredNetworks.map((network) => {
      const column = heights.indexOf(Math.min(...heights));
      const x = column * (cardWidth + HORIZONTAL_MARGIN);
      const y = heights[column];
      heights[column] += CARD_HEIGHT + 10;
      return { ...network, x, y, width: cardWidth, height: CARD_HEIGHT };
    });
    return [heights, gridItems];
  }, [columns, filteredNetworks, width, cardWidth]);

  const transitions = useTransition(gridItems, {
    key: (item: { name: string }) => item.name,
    from: ({ x, y, width, height }) => ({
      transform: `translate3d(${x}px,${y}px,0)`,
      width,
      height,
      opacity: 0,
    }),
    enter: ({ x, y, width, height }) => ({
      transform: `translate3d(${x}px,${y}px,0)`,
      width,
      height,
      opacity: 1,
    }),
    update: ({ x, y, width, height }) => ({
      transform: `translate3d(${x}px,${y}px,0)`,
      width,
      height,
    }),
    leave: { opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  });

  const categories = getUniqueCategories(ASSETS);

  return (
    <div>
      <div className="flex flex-wrap justify-center gap-2 sm:gap-4 mb-4">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setFilter(category)}
            className={`px-4 py-2 ${
              filter === category
                ? "bg-black text-white rounded-full"
                : "bg-gray-200 rounded-full dark:bg-gray-600"
            }`}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </button>
        ))}
      </div>

      <div
        ref={ref}
        className="relative"
        style={{ height: Math.max(...heights), position: "relative" }}
      >
        {transitions((style, network) => (
          <animated.div
            style={style}
            key={network.name}
            className={`absolute border border-opacity-25 border-solid box-border dark:border-gray-600 rounded-lg p-4 shadow-sm transform hover:bg-gray-100 ${
              !network.isActive
                ? "bg-gray-100 dark:bg-gray-800 grayscale dark:text-gray-600"
                : "bg-white dark:bg-gray-800 dark:text-white"
            }`}
          >
            {network.isActive ? null : (
              <div className="absolute top-2 right-2 text-gray-500">
                <Hourglass className="w-4 h-4" />
              </div>
            )}
            <img
              src={network.icon}
              alt={network.label}
              className={`w-16 h-16 mx-auto mb-4 rounded-full border dark:border-gray-600 border-opacity-5 ${
                !network.isActive && "opacity-50"
              }`}
            />
            <h3 className="text-center text-lg font-semibold">
              {network.label}
            </h3>
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default NetworkGrid;
