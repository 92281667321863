import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { downloadCSV } from "@/lib/utils";
import useReportsStore from "@/stores/reports.store";
import { animated, useSpring } from "@react-spring/web";
import { Download, MoveLeft } from "lucide-react";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";

interface AuthCardProps {
  header: string;
  description?: string;
  children: React.ReactNode;
  footer?: string;
  includeBackButton?: boolean;
}

export const DashboardMainCard: FC<AuthCardProps> = ({
  header,
  description,
  children,
  footer,
  includeBackButton,
}) => {
  const navigate = useNavigate();
  const downloadReport: any = useReportsStore((state) => state.downloadReport);

  const downloadReportCsv: any = async () => {
    const data = await downloadReport();
    downloadCSV(data, "report_summary");
  };

  const cardSpring = useSpring({
    from: { opacity: 0, transform: "scale(0.95) translateY(20px)" },
    to: { opacity: 1, transform: "scale(1) translateY(0px)" },
    config: { tension: 200, friction: 30 },
    delay: 100,
  });

  const buttonSpring = useSpring({
    from: { transform: "scale(1)" },
    to: { transform: "scale(1.05)" },
    config: { tension: 180, friction: 14 },
  });

  return (
    <div
      id="page-container"
      className="flex flex-col mx-auto w-full text-gray-900 dark:text-gray-100 dark:bg-gray-900 mb-2"
    >
      <main
        id="page-content"
        className="flex flex-auto flex-col max-w-6xl mx-auto w-full"
      >
        <animated.div style={cardSpring}>
          <Card className="bg-white dark:bg-gray-800 dark:border-gray-800">
            <CardHeader className="text-left">
              <CardTitle className="text-2xl">
                <div className="flex justify-between space-x-2">
                  <p>{header}</p>
                  {header === "Report results" && (
                    <animated.div style={buttonSpring}>
                      <Button
                        variant="outline"
                        className="dark:text-gray-100 dark:border-gray-600 dark:hover:bg-gray-700"
                        onClick={downloadReportCsv}
                      >
                        <Download className="h-4 w-4 mr-2" />
                        Download CSV
                      </Button>
                    </animated.div>
                  )}
                  {!!includeBackButton ? (
                    <animated.div style={buttonSpring}>
                      <Button
                        variant="outline"
                        className="dark:text-gray-100 dark:border-gray-700 dark:hover:bg-gray-700"
                        onClick={() => navigate(-1)}
                      >
                        <MoveLeft strokeWidth={2.5} className="h-4 w-4 mr-2" />{" "}
                        Back
                      </Button>
                    </animated.div>
                  ) : null}
                </div>
              </CardTitle>
              <CardDescription>{description}</CardDescription>
            </CardHeader>
            <div className="px-4">
              <Separator className="mb-2 dark:bg-gray-700" />
            </div>
            <CardContent className="grid gap-4">{children}</CardContent>
            <CardFooter>{footer}</CardFooter>
          </Card>
        </animated.div>
      </main>
    </div>
  );
};
