import * as z from "zod";

export const LoginSchema = z.object({
  email: z.string().email().max(255).min(1, { message: "Email is required" }),
  password: z.string().max(255).min(1, { message: "Password is required" }),
});

export const ContactUsSchema = z.object({
  name: z.string().max(255).min(1, { message: "Name is required" }),
  email: z.string().email().max(255).min(1, { message: "Email is required" }),
  message: z.string().min(5, { message: "Details are required" }),
});

export type ContactUsType = z.infer<typeof ContactUsSchema>;

export const UpdateProfileSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Required" }),
  username: z.string().min(1, { message: "Required" }),
});

export type UpdateProfileType = z.infer<typeof UpdateProfileSchema>;

export const ChangePasswordSchema = z.object({
  newPassword: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" })
    .max(255)
    .regex(/[A-Za-z]/, { message: "Password must include letters" })
    .regex(/[0-9]/, { message: "Password must include numbers" })
    .regex(/[!@#$%^&*]/, {
      message: "Password must include one of the symbols: '!@#$%^&*'",
    }),
  confirmPassword: z
    .string()
    .min(1, { message: "Required" })
    .max(255)
    .refine((data: any) => data.password === data.confirmPassword, {
      message: "Passwords don't match",
      path: ["confirmPassword"], // path of error
    }),
});

export type ChangePasswordType = z.infer<typeof ChangePasswordSchema>;
