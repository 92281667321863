import React, { FC } from "react";

import { Button } from "../ui/button";
import { LOADING_TEXT } from "@/config/general";
import { ReloadIcon } from "@radix-ui/react-icons";

interface LoadingButtonProps {
  children: React.ReactNode;
  className?: string;
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "destructive_outline"
    | "secondary"
    | "ghost"
    | "link"
    | null
    | undefined;
  loading: boolean;
}

export const LoadingButton: FC<LoadingButtonProps> = ({
  children,
  className,
  variant,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <Button
          disabled
          className={!!className ? className : ""}
          variant={variant}
        >
          <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          {LOADING_TEXT}
        </Button>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
