import { GUIDE_PAGE_DESCRIPTION, GUIDE_PAGE_HEADING } from "@/config/general";

import DashboardGuide from "@/components/ledgernalysis/DashboardGuide";
import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import useAuthStore from "@/stores/auth.store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function DashboardGuidePage() {
  const navigate = useNavigate();

  const user = useAuthStore((state) => state.user);

  useEffect(() => {
    if (!user) {
      navigate("/home");
    }
  }, [user]);

  return (
    <DashboardMainCard
      header={GUIDE_PAGE_HEADING}
      description={GUIDE_PAGE_DESCRIPTION}
      includeBackButton={true}
    >
      <DashboardGuide />
    </DashboardMainCard>
  );
}

export default DashboardGuidePage;
