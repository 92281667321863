import {
  BadgeAlert,
  BadgeCheck,
  Copy,
  MoveLeft,
  PartyPopper,
  PlusCircle,
} from "lucide-react";
import { FC, useState } from "react";
import { cn, useCopyToClipboard } from "@/lib/utils";

import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { SourceEntryModal } from "../dialog/SourceEntryModal";
import { SourceModal } from "../dialog/SourcesModal";
import { WorkspaceSwitcherModal } from "../dialog/WorkspaceModal";
import { useNavigate } from "react-router-dom";
import useWorkspaceStore from "@/stores/workspaces.store";
import useReportsStore from "@/stores/reports.store";
import useSourcesStore from "@/stores/sources.store";
import { useToast } from "../ui/use-toast";

interface DashboardGuideItemProps {
  isComplete: boolean;
  title: string;
  shortDescription: string;
  longDescription: string;
  children: React.ReactNode;
}

const DashboardGuideItem: FC<DashboardGuideItemProps> = ({
  isComplete,
  title,
  shortDescription,
  longDescription,
  children,
}) => {
  return (
    <li className={cn("mb-10 ms-6 list-style-type: none")}>
      <span
        className={cn(
          isComplete ? "bg-green-300" : "bg-muted",
          "absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-8 ring-white dark:ring-gray-900"
        )}
      >
        {isComplete ? (
          <BadgeCheck strokeWidth={2.5} />
        ) : (
          <BadgeAlert strokeWidth={2.5} />
        )}
      </span>
      <h3 className="flex items-center mb-1 pt-0.5 text-lg font-semibold text-gray-900 dark:text-white">
        {title}
      </h3>
      <p className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
        {shortDescription}
      </p>
      <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
        {longDescription}
      </p>
      {children}
    </li>
  );
};

function DashboardGuide() {
  const navigate = useNavigate();

  const { toast } = useToast();

  const [isOpenWorkspaceModal, setIsOpenWorkspaceModal] = useState(false);
  const [isSourceModal, setIsSourceModal] = useState(false);
  const [isSourceEntityModal, setIsSourceEntityModal] = useState(false);

  const workspaces = useWorkspaceStore.getState().workspaces;
  const sourceGroups = useSourcesStore.getState().sourceGroups;
  const reports = useReportsStore.getState().reportGroups;

  const hasWorkspaces = () => {
    return workspaces?.length > 0;
  };

  const hasSources = () => {
    return sourceGroups?.length > 0;
  };

  const hasSourceEntities = () => {
    return sourceGroups?.reduce((acc, curr: any) => acc + curr.Count, 0) > 0;
  };

  const hasReports = () => {
    return reports?.length > 0;
  };

  const completed = () => {
    return (
      hasWorkspaces() && hasSources() && hasSourceEntities() && hasReports()
    );
  };

  const [, copy] = useCopyToClipboard();

  const copyValue = (value: string) => {
    copy(value);
    toast({
      title: "Copied Value!",
    });
  };

  return (
    <div className="ml-10 mt-4">
      <div className="relative border-s border-gray-200 dark:border-gray-700">
        <ul className="list-none list-inside">
          <DashboardGuideItem
            isComplete={hasWorkspaces()}
            title={"Create a Workspace"}
            shortDescription={
              "A Workspace is similar to a profile. (ex. Exchange A, Client X or Audit 2022)"
            }
            longDescription={
              "Creating a Workspace is the first step. We recommend creating a new Workspace for every Client or Audit"
            }
          >
            <WorkspaceSwitcherModal
              isOpen={isOpenWorkspaceModal}
              setIsOpen={setIsOpenWorkspaceModal}
            >
              <Button
                variant="outline"
                onClick={() => setIsOpenWorkspaceModal(!isOpenWorkspaceModal)}
              >
                <PlusCircle strokeWidth={2.5} className="h-4 w-4 mr-2" /> Create
                Workspace
              </Button>
            </WorkspaceSwitcherModal>
          </DashboardGuideItem>

          <DashboardGuideItem
            isComplete={hasSources()}
            title={"Create a Source"}
            shortDescription={
              "A Source is a bucket in which Source Entries is grouped (Next step)."
            }
            longDescription={
              "Creating a source is the second step. Sources are used to organise a workspace, by grouping entries together"
            }
          >
            <ul className="space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400 mb-4">
              <li>Use the Side Navigation Bar and navigate to Sources.</li>
              <li>
                Click on the New Source button and Create a Source named
                "Bitcoin Rich Wallets" and of type BALA
              </li>
            </ul>
            <SourceModal
              isEditDialogOpen={isSourceModal}
              setIsEditDialogOpen={setIsSourceModal}
            />
            <Button
              variant="outline"
              onClick={() => navigate("/sources")}
              disabled={!hasWorkspaces()}
            >
              Navigate to Sources
            </Button>
          </DashboardGuideItem>

          <DashboardGuideItem
            isComplete={hasSourceEntities()}
            title={"Create a Source Entry"}
            shortDescription={
              "Source Entries contain input data such as wallet addresses, digital signatures or exchange API keys."
            }
            longDescription={
              "Source Entries are added to a source (previous step), as single entries or in bulk, using our CSV templates."
            }
          >
            <SourceEntryModal
              isEditDialogOpen={isSourceEntityModal}
              setIsEditDialogOpen={setIsSourceEntityModal}
            />
            <ul className="space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400 mb-4">
              <li>
                Navigate to the Source that you created and click on it or the
                Manage Entries button.
              </li>
              <li>Click on the Add Entry button.</li>
              <li>
                You can use richest Bitcoin address as a test entry:
                <div className="flex w-full max-w-sm items-center space-x-1 my-2 ml-5">
                  <Input
                    type="text"
                    value="385ZuqXbrE3dciYTL7zPM9LkNduB9CoCHT"
                    className="bg-gray-900 text-white"
                    disabled={true}
                  />
                  <Button
                    type="submit"
                    variant="outline"
                    onClick={() =>
                      copyValue("385ZuqXbrE3dciYTL7zPM9LkNduB9CoCHT")
                    }
                  >
                    <Copy />
                  </Button>
                </div>
              </li>
              <li>Select the Network: BTC and the Asset: BTC</li>
            </ul>
          </DashboardGuideItem>

          <DashboardGuideItem
            isComplete={hasReports()}
            title={"Run Report"}
            shortDescription={"A Report contains the results of a Source"}
            longDescription={
              "Running a Report is the final step. A Report contains details about a Source, such as Balances, Risks, Transactions and Charts. It is displayed on the app, and can be downloaded as a CSV file."
            }
          >
            <ul className="space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400 mb-4">
              <li>
                Inside the Source Entries page click on the Run Report button on
                the top right.
              </li>
              <li>Navigate to Reports page to view your report</li>
            </ul>
          </DashboardGuideItem>

          <li className="ms-6">
            <span
              className={cn(
                completed() ? "bg-primary" : "bg-muted",
                "absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-8 ring-white dark:ring-gray-900"
              )}
            >
              <PartyPopper strokeWidth={2.5} size={18} />
            </span>
            <h3 className="mb-1 text-lg font-semibold text-gray-900 dark:text-white">
              Well done!
            </h3>
            <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"></time>
            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
              You completed the guide!
            </p>
          </li>
        </ul>
      </div>

      <Button variant="outline" onClick={() => navigate(-1)} className="mt-8">
        <MoveLeft strokeWidth={2.5} className="h-4 w-4 mr-2" /> Back
      </Button>
    </div>
  );
}

export default DashboardGuide;
