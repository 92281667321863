import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import React, { FC } from "react";

import { Separator } from "@/components/ui/separator";
import { CopyrightBanner } from "../misc/CopyrightBanner";
import logo from "/logo-small.svg";

interface AuthCardProps {
  header: string;
  description?: string;
  children: React.ReactNode;
  footer?: string;
}

export const AuthCard: FC<AuthCardProps> = ({
  header,
  description,
  children,
  footer,
}) => {
  return (
    <div className="flex flex-col mx-auto w-full min-h-screen min-w-[320px] dark:text-gray-100 dark:bg-gray-900">
      <main id="page-content" className="flex flex-auto flex-col max-w-full">
        <div className="min-h-screen flex items-center justify-center relative overflow-hidden max-w-10xl mx-auto p-4 lg:p-8 w-full">
          <section className="py-6 w-full max-w-xl">
            <div className="flex">
              <div className="self-center m-auto my-8">
                <div className="flex items-center space-x-3">
                  <a
                    href="/"
                    className="flex items-center space-x-3 rtl:space-x-reverse"
                  >
                    <span className="self-center text-[2rem] font-semibold whitespace-nowrap text-gray-900 dark:text-white">
                      Ledgernalysis
                    </span>
                    <img src={logo} className="h-8" alt="Logo" />
                  </a>
                </div>
              </div>
            </div>
            <Card className="bg-white dark:bg-gray-800 border dark:border-gray-700">
              <CardHeader className="text-center space-y-1">
                <CardTitle className="text-2xl text-gray-900 dark:text-gray-100">
                  {header}
                </CardTitle>
                <CardDescription className="text-gray-700 dark:text-gray-400">
                  {description}
                </CardDescription>
              </CardHeader>
              <div className="px-4">
                <Separator className="my-2 dark:bg-gray-700" />
              </div>
              <CardContent className="grid gap-4">{children}</CardContent>
              <CardFooter className="text-gray-700 dark:text-gray-400">
                {footer}
                <CopyrightBanner />
              </CardFooter>
            </Card>
          </section>
        </div>
      </main>
    </div>
  );
};
