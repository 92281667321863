import { cn } from "@/lib/utils";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import * as React from "react";

type ProgressProps = React.ComponentPropsWithoutRef<
  typeof ProgressPrimitive.Root
> & {
  value?: number | string;
  className?: string;
};

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, ...props }, ref) => {
  let progressValue = 0;

  if (typeof value === "number") {
    progressValue = value;
  } else if (typeof value === "string") {
    const parsedValue = parseFloat(value);
    progressValue = isNaN(parsedValue) ? 0 : parsedValue;
  }

  let colorClass = "bg-primary";

  if (progressValue >= 100) {
    colorClass = "bg-green-500";
  } else if (progressValue >= 50) {
    colorClass = "bg-yellow-500";
  } else if (progressValue >= 20) {
    colorClass = "bg-orange-500";
  }

  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        "relative h-2 w-full overflow-hidden rounded-full bg-primary/20",
        className
      )}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={cn("h-full w-full flex-1 transition-all", colorClass)}
        style={{ transform: `translateX(-${100 - progressValue}%)` }}
      />
    </ProgressPrimitive.Root>
  );
});

Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
