import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { FC } from "react";

interface AlertDestructiveProps {
  title: string;
  description: string;
}

export const AlertDestructive: FC<AlertDestructiveProps> = ({
  title,
  description,
}) => {
  return (
    <Alert variant="destructive">
      <ExclamationTriangleIcon className="h-4 w-4" />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
};
