import { useState, useEffect, memo } from "react";

import React from "react";

interface CachedImageProps {
  src: string;
  alt: string;
  ref?: React.Ref<HTMLImageElement>;
  className?: string;
}

const CachedImage = memo(({ src, alt, ...otherProps }: CachedImageProps) => {
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(() => {
    if (src !== imageSrc) {
      const img = new Image();
      img.onload = () => setImageSrc(src);
      img.src = src;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return <img src={imageSrc} alt={alt} {...otherProps} />;
});

export default CachedImage;
