import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { Outlet } from "react-router-dom";
import useAuthStore from "@/stores/auth.store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function DashboardSideMenu() {
  const navigate = useNavigate();

  const user = useAuthStore((state) => state.user);

  useEffect(() => {
    if (!user) {
      navigate("/home");
    }
  }, [user]);

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
}

export default DashboardSideMenu;
