import { animated, useSpring } from "@react-spring/web";
import React from "react";

interface AnimatedCardProps {
  children: React.ReactNode;
  delay?: number;
  className?: string;
}

const AnimatedCard: React.FC<AnimatedCardProps> = ({ children, delay = 100, className }) => {
  const cardSpring = useSpring({
    from: { opacity: 0, transform: "scale(0.95) translateY(20px)" },
    to: { opacity: 1, transform: "scale(1) translateY(0px)" },
    config: { tension: 200, friction: 30 },
    delay: delay,
  });

  return (
    <animated.div style={cardSpring} className={className}>
      {children}
    </animated.div>
  );
};

export default AnimatedCard;
