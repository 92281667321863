export const networksResponse = {
  version: 1,
  code: 1,
  message: "Network list.",
  data: {
    types: {
      BALA: {
        ADA: {
          ADA: {
            address: "ADA",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
        ALGO: {
          ALGO: {
            address: "ALGO",
            version: "1.0",
            native: true,
            active: "A",
          },
          USDC: {
            address: "31566704",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDT: {
            address: "312769",
            version: "1.0",
            native: false,
            active: "A",
          },
        },
        ARB: {
          ARB: {
            address: "--",
            version: "1.0",
            decimals: 18,
            native: true,
            active: "A",
          },
          ETH: {
            address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
            version: "1.1",
            decimals: 18,
            native: false,
            active: "A",
          },
          USDC: {
            address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
            version: "1.1",
            decimals: 6,
            native: false,
            active: "A",
          },
          USDT: {
            address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
            version: "1.1",
            decimals: 6,
            native: false,
            active: "A",
          },
          UNI: {
            address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
            version: "1.0",
            decimals: 18,
            native: false,
            active: "A",
          },
          DAI: {
            address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
            version: "1.0",
            decimals: 18,
            native: false,
            active: "A",
          },
          LINK: {
            address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
            version: "1.0",
            decimals: 18,
            native: false,
            active: "A",
          },
        },
        ATOM: {
          ATOM: {
            address: "uatom",
            version: "1.0",
            native: true,
            active: "A",
          },
          "ATOM STAKE": {
            address: "uatom",
            version: "1.0",
            native: false,
            active: "A",
          },
        },
        AVAXC: {
          AVAX: {
            address: "AVAX",
            version: "1.0",
            native: true,
            active: "A",
          },
          BUSD: {
            address: "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDC: {
            address: "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDT: {
            address: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
            version: "1.1",
            native: false,
            active: "A",
          },
          XRP: {
            address: "0x822c433922F80F905E4f6D052BDA468389c8C379",
            version: "1.0",
            native: false,
            active: "A",
          },
          MATIC: {
            address: "0xf2f13f0B7008ab2FA4A2418F4ccC3684E49D20Eb",
            version: "1.0",
            native: false,
            active: "A",
          },
          SHIB: {
            address: "0x02D980A0D7AF3fb7Cf7Df8cB35d9eDBCF355f665",
            version: "1.0",
            native: false,
            active: "A",
          },
          PAXG: {
            address: "0x1687b16087B576E403C8d6926fBc0798e48FD0de",
            version: "1.0",
            native: false,
            active: "A",
          },
          DAI: {
            address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
            version: "1.0",
            native: false,
            active: "A",
          },
          LINK: {
            address: "0x5947BB275c521040051D82396192181b413227A3",
            version: "1.0",
            native: false,
            active: "A",
          },
          UNI: {
            address: "0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580",
            version: "1.0",
            native: false,
            active: "A",
          },
        },
        BNB: {
          BTC: {
            address: "BTCB-1DE",
            version: "1.1",
            native: false,
            active: "A",
          },
          BUSD: {
            address: "BUSD-BD1",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDC: {
            address: "USDC-CD2",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDT: {
            address: "USDT-6D8",
            version: "1.1",
            native: false,
            active: "A",
          },
          ETH: {
            address: "ETH-1C9",
            version: "1.1",
            native: false,
            active: "A",
          },
          BNB: {
            address: "BNB",
            version: "1.1",
            native: true,
            active: "A",
          },
          MATIC: {
            address: "MATIC-84A",
            version: "1.0",
            native: false,
            active: "A",
          },
          DAI: {
            address: "DAI-D75",
            version: "1.0",
            native: false,
            active: "A",
          },
          UNI: {
            address: "UNI-DD8",
            version: "1.0",
            native: false,
            active: "A",
          },
          TRX: {
            address: "TRXB-2E6",
            version: "1.0",
            native: false,
            active: "A",
          },
        },
        BSC: {
          ADA: {
            address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
            version: "1.0",
            native: false,
            active: "A",
          },
          ATOM: {
            address: "0x0Eb3a705fc54725037CC9e008bDede697f62F335",
            version: "1.0",
            native: false,
            active: "A",
          },
          AVAX: {
            address: "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
            version: "1.0",
            native: false,
            active: "A",
          },
          BCH: {
            address: "0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
            version: "1.0",
            native: false,
            active: "A",
          },
          BNB: {
            address: "BNB",
            version: "1.1",
            native: true,
            active: "A",
          },
          BTC: {
            address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
            version: "1.1",
            native: false,
            active: "A",
          },
          BUSD: {
            address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            version: "1.1",
            native: false,
            active: "A",
          },
          DAI: {
            address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
            version: "1.0",
            native: false,
            active: "A",
          },
          DG: {
            address: "0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19",
            version: "1.0",
            native: false,
            active: "A",
          },
          DOGE: {
            address: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
            version: "1.0",
            native: false,
            active: "A",
          },
          DOT: {
            address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
            version: "1.0",
            native: false,
            active: "A",
          },
          EOS: {
            address: "0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6",
            version: "1.0",
            native: false,
            active: "A",
          },
          ETH: {
            address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
            version: "1.1",
            native: false,
            active: "A",
          },
          LINK: {
            address: "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
            version: "1.0",
            native: false,
            active: "A",
          },
          MATIC: {
            address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
            version: "1.0",
            native: false,
            active: "A",
          },
          PAXG: {
            address: "0x7950865a9140cB519342433146Ed5b40c6F210f7",
            version: "1.0",
            native: false,
            active: "A",
          },
          SHIB: {
            address: "0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
            version: "1.0",
            native: false,
            active: "A",
          },
          UNI: {
            address: "0xBf5140A22578168FD562DCcF235E5D43A02ce9B1",
            version: "1.0",
            native: false,
            active: "A",
          },
          XMR: {
            address: "0xae25ae116742220ebb40b9a38913a7ed8f69d45a",
            version: "1.0",
            native: false,
            active: "A",
          },
          XRP: {
            address: "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
            version: "1.0",
            native: false,
            active: "A",
          },
          USDC: {
            address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDT: {
            address: "0x55d398326f99059ff775485246999027b3197955",
            version: "1.1",
            native: false,
            active: "A",
          },
        },
        BTC: {
          BTC: {
            address: "BTC",
            version: "1.1",
            native: true,
            active: "A",
          },
        },
        BSV: {
          BSV: {
            address: "BSV",
            version: "1.1",
            native: true,
            active: "N",
          },
        },
        BCH: {
          BCH: {
            address: "BCH",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
        DOGE: {
          DOGE: {
            address: "DOGE",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
        DOT: {
          DOT: {
            address: "DOT",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
        EOS: {
          USDT: {
            address: "tethertether",
            version: "1.1",
            native: false,
            active: "A",
          },
          EOS: {
            address: "EOS",
            version: "1.0",
            native: true,
            active: "N",
          },
        },
        ETC: {
          ETC: {
            address: "ETC",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
        ETH: {
          BNB: {
            address: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
            version: "1.2",
            native: false,
            active: "A",
          },
          BTC: {
            address: "0x9BE89D2a4cd102D8Fecc6BF9dA793be995C22541",
            version: "1.1",
            native: false,
            active: "A",
          },
          BUSD: {
            address: "0x4fabb145d64652a948d72533023f6e7a623c7c53",
            version: "1.1",
            native: false,
            active: "A",
          },
          DAI: {
            address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
            version: "1.0",
            native: false,
            active: "A",
          },
          DG: {
            address: "0x4b520c812E8430659FC9f12f6d0c39026C83588D",
            version: "1.0",
            native: false,
            active: "A",
          },
          ETH: {
            address: "ETH",
            version: "1.0",
            native: true,
            active: "A",
          },
          LINK: {
            address: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
            version: "1.0",
            native: false,
            active: "A",
          },
          MATIC: {
            address: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
            version: "1.0",
            native: false,
            active: "A",
          },
          PAXG: {
            address: "0x45804880De22913dAFE09f4980848ECE6EcbAf78",
            version: "1.0",
            native: false,
            active: "A",
          },
          SHIB: {
            address: "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
            version: "1.0",
            native: false,
            active: "A",
          },
          UNI: {
            address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
            version: "1.0",
            native: false,
            active: "A",
          },
          USDC: {
            address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDT: {
            address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            version: "1.1",
            native: false,
            active: "A",
          },
        },
        ETH2: {
          ETH: {
            address: "ETH",
            version: "1.0",
            native: true,
            active: "N",
          },
        },
        EWT: {
          ETH: {
            address: "EWT",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
        FLOW: {
          FLOW: {
            address: "FLOW",
            version: "1.0",
            native: true,
            active: "N",
          },
        },
        FTM: {
          FTM: {
            address: "0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb",
            version: "1.0",
            native: true,
            active: "A",
          },
          USDT: {
            address: "0x4ECaBa5870353805a9F068101A40E0f32ed605C6",
            version: "1.0",
            native: false,
            active: "A",
          },
          AVAX: {
            address: "0x511D35c52a3C244E7b8bd92c0C297755FbD89212",
            version: "1.0",
            native: false,
            active: "A",
          },
          ETH: {
            address: "0x658b0c7613e890EE50B8C4BC6A3f41ef411208aD",
            version: "1.0",
            native: false,
            active: "A",
          },
          BTC: {
            address: "0xe1146b9AC456fCbB60644c36Fd3F868A9072fc6E",
            version: "1.0",
            native: false,
            active: "A",
          },
          TUSD: {
            address: "0x9879aBDea01a879644185341F7aF7d8343556B7a",
            version: "1.0",
            native: false,
            active: "A",
          },
          LINK: {
            address: "0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8",
            version: "1.0",
            native: false,
            active: "A",
          },
        },
        GNO: {
          GNO: {
            address: "0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb",
            version: "1.0",
            native: true,
            active: "A",
          },
          USDT: {
            address: "0x4ECaBa5870353805a9F068101A40E0f32ed605C6",
            version: "1.0",
            native: false,
            active: "A",
          },
          USDC: {
            address: "0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83",
            version: "1.0",
            native: false,
            active: "A",
          },
          MATIC: {
            address: "0x7122d7661c4564b7C6Cd4878B06766489a6028A2",
            version: "1.0",
            native: false,
            active: "A",
          },
          BTC: {
            address: "0x8e5bBbb09Ed1ebdE8674Cda39A0c169401db4252",
            version: "1.0",
            native: false,
            active: "A",
          },
          UNI: {
            address: "0x4537e328Bf7e4eFA29D05CAeA260D7fE26af9D74",
            version: "1.0",
            native: false,
            active: "A",
          },
          LINK: {
            address: "0xE2e73A1c69ecF83F464EFCE6A5be353a37cA09b2",
            version: "1.0",
            native: false,
            active: "A",
          },
        },
        KCC: {
          BTC: {
            address: "0xfa93c12cd345c658bc4644d1d4e1b9615952258c",
            version: "1.1",
            native: false,
            active: "A",
          },
          ETH: {
            address: "0xf55af137a98607f7ed2efefa4cd2dfe70e4253b1",
            version: "1.1",
            native: false,
            active: "A",
          },
          LINK: {
            address: "0x47841910329aaa6b88d5e9dcde9000195151dc72",
            version: "1.0",
            native: false,
            active: "A",
          },
          SHIB: {
            address: "0x73b6086955c820370a18002f60e9b51fb67d7e1a",
            version: "1.0",
            native: false,
            active: "A",
          },
          UNI: {
            address: "0xee58e4d62b10a92db1089d4d040b759c28ae16cd",
            version: "1.0",
            native: false,
            active: "A",
          },
          USDC: {
            address: "0x980a5afef3d17ad98635f6c5aebcbaeded3c3430",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDT: {
            address: "0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48",
            version: "1.1",
            native: false,
            active: "A",
          },
        },
        KAVA: {
          KAVA: {
            address: "ukava",
            version: "1.0",
            native: true,
            active: "N",
          },
        },
        KSM: {
          KSM: {
            address: "KSM",
            version: "1.1",
            native: true,
            active: "A",
          },
        },
        LTC: {
          LTC: {
            address: "LTC",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
        LUNA: {
          LUNA: {
            address: "uluna",
            version: "1.0",
            native: true,
            active: "N",
          },
        },
        MATIC: {
          DAI: {
            address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
            version: "1.0",
            native: false,
            active: "A",
          },
          LINK: {
            address: "0xb0897686c545045aFc77CF20eC7A532E3120E0F1",
            version: "1.0",
            native: false,
            active: "A",
          },
          MATIC: {
            address: "-",
            version: "1.0",
            native: true,
            active: "A",
          },
          PAXG: {
            address: "0x553d3D295e0f695B9228246232eDF400ed3560B5",
            version: "1.0",
            native: false,
            active: "A",
          },
          UNI: {
            address: "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
            version: "1.0",
            native: false,
            active: "A",
          },
          BUSD: {
            address: "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDC: {
            address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDT: {
            address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
            version: "1.1",
            native: false,
            active: "A",
          },
        },
        MINA: {
          MINA: {
            address: "MINA",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
        MOVR: {
          MOVR: {
            address: "None",
            version: "1.2",
            native: true,
            active: "A",
          },
        },
        OPT: {
          DAI: {
            address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
            version: "1.0",
            native: false,
            active: "A",
          },
          LINK: {
            address: "0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6",
            version: "1.0",
            native: false,
            active: "A",
          },
          USDT: {
            address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
            version: "1.0",
            native: false,
            active: "A",
          },
          USDC: {
            address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
            version: "1.0",
            native: false,
            active: "A",
          },
          ETH: {
            address: "0xe5efc7a009a0c12d31ca933fc4496ce299d0f9b6",
            version: "1.0",
            native: false,
            active: "A",
          },
        },
        OMNI: {
          BTC: {
            address: "0",
            version: "1.0",
            native: false,
            active: "A",
          },
          USDT: {
            address: "31",
            version: "1.0",
            native: false,
            active: "A",
          },
        },
        SOL: {
          SOL: {
            address: "-",
            version: "1.0",
            native: true,
            active: "A",
          },
          USDC: {
            address: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
            version: "1.0",
            native: false,
            active: "A",
          },
          USDT: {
            address: "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
            version: "1.0",
            native: false,
            active: "A",
          },
          BASIS: {
            address: "Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa",
            version: "1.0",
            native: false,
            active: "A",
          },
        },
        TRX: {
          BTC: {
            address: "TN3W4H6rK2ce4vX9YnFQHwKENnHjoxb3m9",
            version: "1.1",
            native: false,
            active: "A",
          },
          DOGE: {
            address: "THbVQp8kMjStKNnf2iCY6NEzThKMK5aBHg",
            version: "1.1",
            native: false,
            active: "A",
          },
          ETH: {
            address: "THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDC: {
            address: "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8",
            version: "1.1",
            native: false,
            active: "A",
          },
          USDT: {
            address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
            version: "1.1",
            native: false,
            active: "A",
          },
          TRX: {
            address: "TRX",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
        XTZ: {
          XTZ: {
            address: "-",
            version: "1.0",
            native: true,
            active: "B",
          },
          USDT: {
            address: "KT1XnTn74bUtxHfDtBmm2bGZAQfhPbvKWR8o",
            version: "1.0",
            native: false,
            active: "N",
          },
        },
        XLM: {
          XRP: {
            address: "GBXRPL45NPHCVMFFAYZVUVFFVKSIZ362ZXFP7I2ETNQ3QKZMFLPRDTD5",
            version: "1.1",
            code: "XRP",
            native: false,
            active: "N",
          },
          BTC: {
            address: "GDPJALI4AZKUU2W426U5WKMAT6CN3AJRPIIRYR2YM54TL2GDWO5O2MZM",
            version: "1.1",
            code: "BTC",
            native: false,
            active: "N",
          },
          DOGE: {
            address: "GCNSGHUCG5VMGLT5RIYYZSO7VQULQKAJ62QA33DBC5PPBSO57LFWVV6P",
            version: "1.0",
            code: "DOGE",
            native: false,
            active: "N",
          },
          ETH: {
            address: "GBFXOHVAS43OIWNIO7XLRJAHT3BICFEIKOJLZVXNT572MISM4CMGSOCC",
            version: "1.1",
            code: "ETH",
            native: false,
            active: "N",
          },
          LINK: {
            address: "GBDEVU63Y6NTHJQQZIKVTC23NWLQVP3WJ2RI2OTSJTNYOIGICST6DUXR",
            version: "1.0",
            code: "LINK",
            native: false,
            active: "N",
          },
          USDC: {
            address: "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN",
            version: "1.1",
            code: "USDC",
            native: false,
            active: "N",
          },
          XLM: {
            address: "native",
            version: "1.0",
            code: "XLM",
            native: true,
            active: "B",
          },
        },
        XRP: {
          XRP: {
            address: "XRP",
            version: "1.0",
            native: true,
            active: "A",
          },
        },
      },
      RISK: {
        BTC: {
          version: 1,
          native: false,
          active: "A",
        },
      },
      EXCH: {
        VALR: {
          BTC: {
            version: "1.1",
            code: "BTC",
            native: false,
            active: "A",
          },
          ETH: {
            version: "1.1",
            code: "ETH",
            native: false,
            active: "A",
          },
          USDT: {
            version: "1.0",
            code: "USDT",
            native: false,
            active: "A",
          },
          ZAR: {
            version: "1.1",
            code: "ZAR",
            native: false,
            active: "A",
          },
          USD: {
            version: "1.0",
            code: "USD",
            native: false,
            active: "A",
          },
        },
        CR_COM: {
          BTC: {
            version: "1.1",
            code: "BTC",
            native: false,
            active: "A",
          },
          ETH: {
            version: "1.1",
            code: "ETH",
            native: false,
            active: "A",
          },
          USDT: {
            version: "1.0",
            code: "USDT",
            native: false,
            active: "A",
          },
          ZAR: {
            version: "1.1",
            code: "ZAR",
            native: false,
            active: "A",
          },
          USD: {
            version: "1.0",
            code: "USD",
            native: false,
            active: "A",
          },
        },
        OKX: {
          BTC: {
            version: "1.1",
            code: "BTC",
            native: false,
            active: "A",
          },
          ETH: {
            version: "1.1",
            code: "ETH",
            native: false,
            active: "A",
          },
          USDT: {
            version: "1.0",
            code: "USDT",
            native: false,
            active: "A",
          },
          ZAR: {
            version: "1.1",
            code: "ZAR",
            native: false,
            active: "A",
          },
          USD: {
            version: "1.0",
            code: "USD",
            native: false,
            active: "A",
          },
        },
        DERIBIT: {
          BTC: {
            version: "1.1",
            code: "BTC",
            native: false,
            active: "A",
          },
          ETH: {
            version: "1.1",
            code: "ETH",
            native: false,
            active: "A",
          },
          USDT: {
            version: "1.0",
            code: "USDT",
            native: false,
            active: "A",
          },
          ZAR: {
            version: "1.1",
            code: "ZAR",
            native: false,
            active: "A",
          },
          USD: {
            version: "1.0",
            code: "USD",
            native: false,
            active: "A",
          },
        },
        LUNO: {
          BTC: {
            version: "1.1",
            code: "BTC",
            native: false,
            active: "A",
          },
          ETH: {
            version: "1.1",
            code: "ETH",
            native: false,
            active: "A",
          },
          USDT: {
            version: "1.0",
            code: "USDT",
            native: false,
            active: "A",
          },
          ZAR: {
            version: "1.1",
            code: "ZAR",
            native: false,
            active: "A",
          },
          USD: {
            version: "1.0",
            code: "USD",
            native: false,
            active: "A",
          },
        },
        BINANCE: {
          BTC: {
            version: "1.1",
            code: "BTC",
            native: false,
            active: "A",
          },
          ETH: {
            version: "1.1",
            code: "ETH",
            native: false,
            active: "A",
          },
          USDT: {
            version: "1.0",
            code: "USDT",
            native: false,
            active: "A",
          },
          ZAR: {
            version: "1.1",
            code: "ZAR",
            native: false,
            active: "A",
          },
          USD: {
            version: "1.0",
            code: "USD",
            native: false,
            active: "A",
          },
        },
        KRAKEN: {
          BTC: {
            version: "1.1",
            code: "BTC",
            native: false,
            active: "A",
          },
          ETH: {
            version: "1.1",
            code: "ETH",
            native: false,
            active: "A",
          },
          USDT: {
            version: "1.0",
            code: "USDT",
            native: false,
            active: "A",
          },
          ZAR: {
            version: "1.1",
            code: "ZAR",
            native: false,
            active: "A",
          },
          USD: {
            version: "1.0",
            code: "USD",
            native: false,
            active: "A",
          },
        },
      },
      ACCO: {
        XERO: {
          version: 1,
          native: false,
          active: "A",
        },
      },
      SIGN: {
        BLS: {
          version: 1,
          native: false,
          active: "A",
        },
      },
      PUBK: {
        BTC: {
          version: 1,
          native: false,
          active: "A",
        },
        ETH: {
          version: 1,
          native: false,
          active: "A",
        },
      },
    },
    tokens: {
      version: 1,
      datetimestamp: "2023-08-25T11:05:00Z",
      ALGO: "Algorand",
      ADA: "Cardano",
      ATOM: "Cosmos",
      "ATOM STAKE": "Cosmos Staked",
      AVAX: "Avalanche",
      BASIS: "Basis",
      BCH: "Bitcoin Cash",
      BNB: "Binance Coin",
      BTC: "Bitcoin",
      BUSD: "Binance USD",
      DAI: "Dai",
      DG: "Doge Coin",
      DOGE: "Doge Coin",
      DOT: "Polkadot",
      "DOT STAKE": "Polkadot Staked",
      EOS: "EOS",
      ETC: "Ether Classic",
      ETH: "Ether",
      FLOW: "Flow",
      FTM: "Fantom",
      KAVA: "Kava",
      KSM: "Kusama",
      "KSM STAKE": "Kusama Staked",
      LINK: "Coin Link",
      LUNA: "Terra Luna",
      "LUNA STAKE": "Terra Luna Staked",
      LTC: "Litecoin",
      MATIC: "Polygon",
      MINA: "Mina",
      MOVR: "Moonriver",
      PAXG: "Paxos Gold",
      SHIB: "Shibu Uno",
      SOL: "Solana",
      TRX: "Tron",
      "TRX STAKE": "Tron Staked",
      TUSD: "True USD",
      UNI: "Uniswap",
      USDC: "USD Coin",
      USDT: "USD Tether",
      USD: "United States Dollar",
      XLM: "Stellar",
      XMR: "Monero",
      XRP: "Ripple",
      XTZ: "Tezos",
      ZAR: "South African Rand",
    },
  },
  errors: [],
};
