import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";

import { Card } from "../ui/card";
import { ChangePasswordForm } from "../forms/ChangePasswordForm";
import { DashboardMainCard } from "../cards/DashboardMainCard";
import { DeleteAccountForm } from "../forms/DeleteAccountForm";
import React from "react";
import { UpdateProfileForm } from "../forms/UpdateProfileForm";

export const AccountManagement: React.FC = () => {
  return (
    <div>
      <Tabs defaultValue="update-account">
        <Card className="p-2 bg-white">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="update-account">Update Profile</TabsTrigger>
            <TabsTrigger value="change-password">Change Password</TabsTrigger>
            <TabsTrigger value="delete-account">Delete Account</TabsTrigger>
          </TabsList>
        </Card>
        <TabsContent value="update-account">
          <DashboardMainCard
            header={"Update Your Profile"}
            description="You can update your email and username here."
          >
            <UpdateProfileForm />
          </DashboardMainCard>
        </TabsContent>
        <TabsContent value="change-password">
          <DashboardMainCard
            header={"Change Your Password"}
            description="You can update your login password here."
          >
            <ChangePasswordForm />
          </DashboardMainCard>
        </TabsContent>
        <TabsContent value="delete-account">
          <DashboardMainCard
            header={"Change Your Password"}
            description="You can delete your account here."
          >
            <DeleteAccountForm />
          </DashboardMainCard>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default AccountManagement;
