import access from "@/assets/animations/_accessibility.json";
import result from "@/assets/animations/_results.json";
import ui from "@/assets/animations/_userinterface.json";
export const values = [
  {
    title: "Fullstack Secure Crypto Data Platform",
    info: "We adhere to established best practices guided by standards such as OWASP and the CIS Benchmark. All data exchanged with Ledgeranalysis is secured in transit with 256-bit encryption. Our API and application endpoints exclusively use TLS/SSL protocols. Additionally, we ensure that data at rest is protected with industry-standard AES-256 encryption.",
    img: result,
  },
  {
    title: "Easy, Accurate Crypto Reports backed by stable infrastructure",
    info: "There is no hidden magic or mystery behind the scenes. What makes Ledgernalysis truly effective is our substantial investment in highly skilled people and infrastructure that support our core data platform",
    img: access,
  },
  {
    title:
      "Seamless integration with blockchains, DeFi platforms, exchanges, banks, and wallets.",
    info: "Effortlessly track all your assets and transactions across DeFi protocols, wallets, exchanges, and institutional custodians, creating a comprehensive view of your digital asset activity",
    img: ui,
  },
];
