import { z } from "zod";
import Papa from "papaparse";

const BaseSourceEntrySchema = z.object({
  network: z.string().min(1, { message: "Network is required" }),
});

const BalaSchema = BaseSourceEntrySchema.extend({
  address: z.string().min(1, { message: "Address is required" }),
  asset: z.string().min(1, { message: "Asset is required" }),
});

const ExchSchema = BaseSourceEntrySchema.extend({
  secret: z.string().min(1, { message: "Secret is required" }),
  key: z.string().min(1, { message: "Key/Client ID is required" }),
  passphrase: z.string().optional(),
});

const RiskScheme = BaseSourceEntrySchema.extend({
  address: z.string().min(1, { message: "Address is required" }),
});

const SignScheme = BaseSourceEntrySchema.extend({
  message: z.string().min(1, { message: "Message is required" }),
  signature: z.string().min(1, { message: "Signature is required" }),
  pubkey: z.string().min(1, { message: "Public Key is required" }),
});

const PubkScheme = BaseSourceEntrySchema.extend({
  xpubkey: z.string().min(1, { message: "xPubKey is required" }),
  addresstype: z.string().min(1, { message: "Address Type is required" }),
  numberofaddresses: z
    .string()
    .min(1, { message: "Number of Addresses is required" })
    .transform((v) => parseInt(v)),
});

const DefiScheme = BaseSourceEntrySchema.extend({
  address: z.string().min(1, { message: "Address is required" }),
  network: z.string().min(1, { message: "Network is required" }),
  protocol: z.string().min(1, { message: "Protocol is required" }),
});

export const getSchemasBySourceType = (sourceType: string) => {
  switch (sourceType) {
    case "BALA":
      return BalaSchema;
    case "RISK":
      return RiskScheme;
    case "EXCH":
      return ExchSchema;
    case "SIGN":
      return SignScheme;
    case "PUBK":
      return PubkScheme;
    case "DEFI":
      return DefiScheme;
    default:
      return BaseSourceEntrySchema;
  }
};

export const SourceEntrySchema = z.object({
  address: z.string().min(1, { message: "Address is required" }),
  network: z.string().min(1, { message: "Network is required" }),
  asset: z.string().min(1, { message: "Asset is required" }),
});

// "sourceId": "600116ec-89df-4921-99c2-efe1889a3580",
// "sourceGroupId": "aa46bbf1-f53a-4bdd-89f6-24fa35ecdaeb",
// "entry": {
//     "max": 5,
//     "min": 3,
//     "keys": [
//         "xpub6D1LANRFwpC5g2EGvPgU2QcBuqH7cPFc1p5dfruFrzk8iwqpzCdZZM5qjtZQHJGvZmBjbJJVLMGaS7gwhQihVjiSuAKGxLvHmYb2svkR6my",
//         "xpub6C9HgD8RGUJSchXTEx2PBdfDoefhJRh9iL15fcheoALQeyF7mpwxh1HzL3UT1C97mhsCJKX5h1T5pPzPkVX8Zwxv74KBrLwozbzhSKXSfY1",
//         "xpub6D3qsiT7WjHhTkyvBqYB1zKTXNZLXzC2dGqUMiL91cWDGvhbsaonnZtnTymxQfZk2fjPLZRgBfhwXynqXd1Ct3Q4xAu71TFikFcce1YYWow",
//         "xpub6CoG7gSpqzKJqQ69pxoRx4F63Su5yk749Emb1i6qjtVt28H8WELbz9yvvt4gVtKezNCmQSbC1EDeJEmRfJsRZhfPRQ7s731qaAdUebgHtGs",
//         "xpub6DRLRDFmCHQyN9RK39G8usBkCKWaRkfvyQsUBmW8QRD6B9J7MmLQrURjBoJrTiiB9LX1kkXvrVYzCNCSwJ4bxg4GM6L1NbyPwMWF75maiTW"
//     ],
//     "single": false,
//     "network": "BTC"
// },
// "entryType": "PUBK",
// "updated": 1721122262264

export const SourceSchema = z.object({
  entryType: z.string(),
  sourceGroupId: z.string(),
  sourceID: z.string(),
  updated: z.number(),
  entry: z.any(),
});

export type Source = z.infer<typeof SourceSchema>;

export const SourceGroupSchema = z.object({
  // OrgID: z.string().optional(),
  workspaceId: z.string().optional(),
  // ProfileName: z.string().optional(),
  sourceGroupId: z.string().optional(),
  sourceName: z.string().min(1, "SourceName is required."),
  updated: z.number().optional(),
  // UserID: z.string().optional(),
  groupType: z.string().min(1, "GroupType is required."),
});

export type SourceGroup = z.infer<typeof SourceGroupSchema>;

export const validateAndCleanFile = (
  fileData: string,
  type: string
): string => {
  const schema = getSchemasBySourceType(type);
  let parsedData: any[] = [];
  let missingColumns: string[] = [];
  const requiredColumns = Object.keys(schema.shape).map((col) =>
    col.toLowerCase()
  );
  console.log(requiredColumns);
  // Parse the CSV data
  Papa.parse(fileData, {
    header: true,
    skipEmptyLines: true,
    complete: (results) => {
      const columns = results?.meta?.fields?.map((col) => col.toLowerCase());
      // Check for required columns
      missingColumns = requiredColumns.filter((col) => !columns?.includes(col));
      if (missingColumns.length > 0) {
        throw new Error(
          `Missing required columns: ${missingColumns.join(", ")}`
        );
      }
      console.log(results.data);
      // Map to lowercase and filter out empty rows
      parsedData = results.data
        .map((row: any) => {
          const lowerCaseRow: any = {};
          Object.keys(row).forEach((key) => {
            lowerCaseRow[key.toLowerCase()] = row[key];
          });
          return lowerCaseRow;
        })
        .filter((row) => {
          // Filter out rows with empty or insufficient data
          return requiredColumns.every((col) => row[col] && row[col].trim());
        });

      // Extract only the required columns
      parsedData = parsedData.map((row) => {
        const filteredRow: any = {};
        requiredColumns.forEach((col) => {
          // @ts-ignore
          filteredRow[col] = row[col] || "";
        });
        return filteredRow;
      });
    },
  });

  const dataToValidate = parsedData.map((row) => {
    // Lowercase the keys
    const lowerCaseRow: any = {};
    Object.keys(row).forEach((key) => {
      lowerCaseRow[key.toLowerCase()] = row[key];
    });
    return lowerCaseRow;
  });

  const csvSchema = z.array(schema.strict());

  try {
    csvSchema.parse(dataToValidate);
    return Papa.unparse(parsedData);
  } catch (e: any) {
    throw new Error(e?.message);
  }
};
