import { UndoDot, XCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useGuideStore from "@/stores/guide.store";
import { ConfirmDialog } from "../dialog/ConfirmDialog";
import { Button } from "../ui/button";
import image from "/undraw_guide_book.svg";

export const UserGuideCard = () => {
  const navigate = useNavigate();

  const paths = useLocation();

  const [show, setShow] = useState(false);

  const [showGuide, completeGuide] = useGuideStore((state) => [
    state.showGuide,
    state.completeGuide,
  ]);

  const [openDismissDialog, setOpenDismissDialog] = useState(false);

  useEffect(() => {
    const onDashboard = paths.pathname.includes("dashboard");
    setShow(showGuide && !onDashboard);
  }, [paths, showGuide]);

  return (
    <>
      {show && (
        <div className="fixed bottom-0 start-0 z-[60] sm:max-w-xl w-full mx-auto p-6">
          <div className="p-4 border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700 bg-primary">
            <div className="flex gap-x-5">
              <img src={image} className="h-40" />

              <div className="grow">
                <h2 className="text-xl font-semibold text-white text-center ">
                  Ledgernalysis Starter Guide
                </h2>
                <p className="mt-2 text-sm text-center ">
                  Getting lost in Ledgernalysis, Navigate back to the guide
                  menu.
                </p>
                <p className="text-center text-sm text-gray-100">- OR -</p>
                <p className="text-sm text-center ">
                  Mastered Ledgernalysis? Dismiss the guide.
                </p>
                <div className="mt-5 inline-flex gap-x-2">
                  <Button
                    variant="secondary"
                    onClick={() => navigate("/dashboard/guide")}
                  >
                    <UndoDot strokeWidth={2.5} className="h-4 w-4 mr-2" /> Back
                    to the Guide
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setOpenDismissDialog(true)}
                  >
                    <XCircle strokeWidth={2.5} className="h-4 w-4 mr-2" />{" "}
                    Dismiss Guide
                  </Button>
                  <ConfirmDialog
                    title="Dismiss Guide"
                    description="Are you sure you want dismiss the guide? You won't be able to view the guide again."
                    open={openDismissDialog}
                    setOpen={setOpenDismissDialog}
                    severity="info"
                    onConfirm={completeGuide}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
