import { PartyPopper, XCircle } from "lucide-react";

import { Button } from "../ui/button";
import { ConfirmDialog } from "../dialog/ConfirmDialog";
import image from "/undraw_air_support.svg";
import useGuideStore from "@/stores/guide.store";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const DashboardGuideCard = () => {
  const navigate = useNavigate();

  const [openDismissDialog, setOpenDismissDialog] = useState(false);

  const [startGuide, completeGuide] = useGuideStore((state) => [
    state.startGuide,
    state.completeGuide,
  ]);

  return (
    <>
      <div className="bg-primary/70 border border-gray-200 rounded-lg shadow">
        <div className="max-w-[85rem] px-4 py-6 sm:px-6 lg:px-8 lg:py-6 mx-auto">
          <div className="grid items-center grid-cols-4">
            <div className="grid-item col-span-2">
              <p className="inline-block text-sm font-medium text-white">
                Ledgernalysis: A quick starting guide!
              </p>

              <div className="mt-4 md:mb-12">
                <h1 className="mb-4 font-semibold text-gray-800 text-4xl lg:text-5xl dark:text-gray-200">
                  Master Ledgernalysis in{" "}
                  <span className="text-[#ff6584]">4 </span>easy steps
                </h1>
                <p className="text-gray-600 dark:text-gray-400">
                  We will provide you with a quick guide to get you started with
                  Ledgernalysis and take you thought all the vital steps.
                </p>
              </div>
              <Button
                variant="secondary"
                onClick={() => {
                  startGuide(), navigate("/dashboard/guide");
                }}
              >
                <PartyPopper strokeWidth={2.5} className="h-4 w-4 mr-2" />
                Get Started Now
              </Button>
              <Button
                variant="secondary"
                className="ml-2"
                onClick={() => setOpenDismissDialog(true)}
              >
                <XCircle strokeWidth={2.5} className="h-4 w-4 mr-2" /> Dismiss
                Guide
              </Button>
              <ConfirmDialog
                title="Dismiss Guide"
                description="Are you sure you want dismiss the guide? You won't be able to view the guide again."
                open={openDismissDialog}
                setOpen={setOpenDismissDialog}
                severity="info"
                onConfirm={completeGuide}
              />
            </div>
            <div className="grid-item col-span-2 items-center mx-auto">
              <img src={image} className="h-80" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
