import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { ContactUsSchema, ContactUsType } from "@/zod/auth.zod";

import { AlertDestructive } from "@/components/alerts/AlertDestructive";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { CONTACT_US_BUTTON } from "@/config/auth";
import { LOADING_TEXT } from "@/config/general";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Textarea } from "../ui/textarea";
import { useToast } from "../ui/use-toast";

export const ContactUsForm = () => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState<boolean>(false);

  const { toast } = useToast();

  const contactUsForm = useForm<ContactUsType>({
    resolver: zodResolver(ContactUsSchema),
    defaultValues: {
      message: "",
      name: "",
      email: "",
    },
  });

  const onSubmit = async (data: ContactUsType) => {
    const request = {
      ...data,
      access_key: "fdaf11e2-f0bb-438a-90bb-5bd1ded03472",
    };

    const json = JSON.stringify(request);

    try {
      setLoading(true);
      await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      }).then(() => {
        setSentEmail(true);
        toast({
          title: "Email Sent",
          description: "Thank you for contacting us. We will respond soon!",
        });
      });
    } catch (error: any) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...contactUsForm}>
      <div className="mt-4">
        {error ? <AlertDestructive title="Error" description={error} /> : <></>}
      </div>
      <form
        onSubmit={contactUsForm.handleSubmit(onSubmit)}
        className="space-y-8"
      >
        <FormField
          control={contactUsForm.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="dark:text-white">Name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your name"
                  {...field}
                  className="bg-white dark:bg-black"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={contactUsForm.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="dark:text-white">Email</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  className="bg-white dark:bg-black"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={contactUsForm.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="dark:text-white">Details</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter your details here"
                  className="bg-white dark:bg-black resize-none"
                  rows={4}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {loading ? (
          <Button disabled className="w-full" size="lg">
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin dark:text-white" />
            {LOADING_TEXT}
          </Button>
        ) : (
          <Button
            disabled={sentEmail}
            type="submit"
            className="w-full"
            size="lg"
          >
            {CONTACT_US_BUTTON}
          </Button>
        )}
      </form>
    </Form>
  );
};
