export const AboutUsSection = () => {
  return (
    <div
      id="about"
      className="w-full bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_70%,transparent_100%)] dark:bg-[radial-gradient(#ffffff33_1px,#00091d_1px)] bg-[size:20px_20px]"
    >
      <div className="max-w-[85rem] px-4 py-28 sm:px-14 lg:px-18 lg:py-28 mx-auto">
        <div className="max-w-2xl lg:max-w-5xl mx-auto">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
              About Us
            </h1>
          </div>
          <div className="text-center">
            <p className="mt-10 text-gray-600 dark:text-gray-400">
              At{" "}
              <span className="font-bold text-blue-500">
                <a href="https://www.silversixpence.io/">SilverSixpence</a>
              </span>
              , we have always been at the forefront of innovation. With
              Ledgernalysis, we bring our expertise and commitment to the
              intricate world of cryptocurrency accounting and auditing. This
              tool embodies our dedication to clarity, precision, and
              trustworthiness. With our agile team constantly innovating, we're
              ensuring that Ledgernalysis not only meets the needs of today but
              evolves with the ever-changing crypto landscape. We've made it our
              mission to simplify the complex, offering a solution that
              transforms thousands of data points into actionable and
              comprehensible insights.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
