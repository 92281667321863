// src/components/cards/DashboardWorkspaceCard.tsx
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Skeleton } from "@/components/ui/skeleton";

interface DashboardWorkspaceCardProps {
  selectedWorkspace: { workspaceName: string } | undefined;
  workspaces: Array<{ workspaceName: string }>;
  loadingProfile: boolean;
}

const DashboardWorkspaceCard: React.FC<DashboardWorkspaceCardProps> = ({
  selectedWorkspace,
  workspaces,
  loadingProfile,
}) => {
  return (
    <div className="w-full">
      <div className="flex items-center p-3">
        <Avatar className="mx-4 h-8 w-8">
          <AvatarImage src={`https://avatar.vercel.sh/$1.png`} alt={"W"} />
          <AvatarFallback>W</AvatarFallback>
        </Avatar>
        <div className="font-bold text-gray-800 dark:text-gray-100 text-3xl">
          {!!selectedWorkspace && !loadingProfile ? (
            workspaces.length === 0 ? (
              <span className="text-red-800 dark:text-red-400">
                No Workspaces created yet!
              </span>
            ) : (
              selectedWorkspace.workspaceName
            )
          ) : (
            <Skeleton className="w-[250px] h-[30px] bg-gray-300 dark:bg-gray-700" />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardWorkspaceCard;
