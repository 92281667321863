import { createJSONStorage, persist } from "zustand/middleware";

import { create } from "zustand";

type GuideState = {
  showInitialGuide: boolean;
  showGuide: boolean;
  stepTitle: string;
  stepDescription: string;
  startGuide: () => void;
  completeGuide: () => void;
  setStepTitle: (value: string) => void;
  setStepDescription: (value: string) => void;
};

export const useGuideStore = create(
  persist<GuideState>(
    (set) => ({
      showInitialGuide: true,
      showGuide: false,
      stepTitle: "Create a Workspace (1/4)",
      stepDescription:
        "Click on the side tab under the Workspace heading to create a Workspace.",
      startGuide: () => set({ showGuide: true }),
      completeGuide: () => set({ showGuide: false, showInitialGuide: false }),
      setStepTitle: (value: string) => set({ stepTitle: value }),
      setStepDescription: (value: string) => set({ stepDescription: value }),
    }),
    {
      name: "guide-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useGuideStore;
