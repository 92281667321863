import apiService from "./apiService";

export const getAllWorkspaces = async () => {
  return apiService.get(`/getWorkspaces`);
};

export const getPagingWorkspaces = async (offset: number, limit: number) => {
  return apiService.get(`/getWorkspaces?offset=${offset}&limit=${limit}`);
};

export const getWorkspaceDashboard = async (
  workspaceId: string,
  startDate: string,
  endDate: string
) => {
  return apiService.get(`/workspace`, {
    params: {
      workspaceId: workspaceId,
      startDate: startDate,
      endDate: endDate,
    },
  });
};

export const createWorkspace = async (
  workspaceName: string,
  currency: string = "USD"
) => {
  return apiService.put(`/putWorkspace`, { workspaceName, currency });
};

export const updateWorkspace = async (
  workspaceId: string,
  data: { workspaceName?: string; currency?: string }
) => {
  return apiService.patch(`/patchWorkspace`, { workspaceId, ...data });
};
