import { animated, useSpring } from "@react-spring/web";
import React, { ReactNode } from "react";
import { useInView } from "react-intersection-observer";

interface AnimatedSectionProps {
  children: ReactNode;
  id: string;
  threshold?: number;
}

export const AnimatedSection: React.FC<AnimatedSectionProps> = ({
  children,
  id,
  threshold = 0.1,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: threshold,
  });

  const animation = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(50px)",
    },
    config: { tension: 170, friction: 26 },
  });

  return (
    <animated.div ref={ref} style={animation} id={id}>
      {children}
    </animated.div>
  );
};
