import { useEffect } from "react";

import { AboutUsSection } from "@/components/home/AboutUsSection";
import { CarouselSection } from "@/components/home/CarouselSection";
import { ContactUsSection } from "@/components/home/ContactUsSection";
import { FeatureSection } from "@/components/home/FeatureSection";
import { FooterSection } from "@/components/home/FooterSection";
import { HeroSection } from "@/components/home/HeroSection";
import { IntegrationsSection } from "@/components/home/IntegrationsSection";
import { NavBar } from "@/components/home/NavBar";
import { AnimatedSection } from "@/components/misc/AnimatedSection";
import { Separator } from "@/components/ui/separator";
import useAuthStore from "@/stores/auth.store";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <>
      <NavBar />
      <AnimatedSection id="hero-section">
        <HeroSection />
      </AnimatedSection>
      <AnimatedSection id="card-section">
        <CarouselSection />
      </AnimatedSection>
      <AnimatedSection id="integration-section">
        <IntegrationsSection />
      </AnimatedSection>
      <AnimatedSection id="about-us-section">
        <AboutUsSection />
      </AnimatedSection>
      <AnimatedSection id="feature-section">
        <FeatureSection />
      </AnimatedSection>
      <AnimatedSection id="contact-us-section">
        <ContactUsSection />
      </AnimatedSection>
      <Separator />
      <FooterSection />
    </>
  );
}

export default Home;
