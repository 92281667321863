import ErrorBoundary from "@/components/ErrorBoundary";
import { ThemeProvider } from "@/components/theme/ThemeProvider";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import routes from "./routes";

const App = () => {
  const router = createBrowserRouter(routes);

  return (
    <ErrorBoundary
      fallback={<div>Oops! Something went wrong. Please try again later.</div>}
    >
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <RouterProvider router={router} />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
