import { Mail, MapPinned } from "lucide-react";

import { FC } from "react";
import { ContactUsForm } from "../forms/ContactForm";

interface ServiceCardProps {
  icon: React.ReactNode;
  title: string;
  details: string;
}

const Card: FC<ServiceCardProps> = ({ icon, title, details }) => {
  return (
    <div className="flex gap-x-7 py-6">
      {icon}
      <div className="grow">
        <h3 className="font-semibold text-gray-800 dark:text-gray-200">
          {title}
        </h3>
        <p className="mt-1 text-sm text-gray-500">{details}</p>
      </div>
    </div>
  );
};

export const ContactUsSection = () => {
  return (
    <section id="contact" className="dark:bg-gray-800 dark:divide-gray-50">
      <div className="max-w-[85rem] px-4 pb-10 sm:px-14 lg:px-18 lg:py-28 mx-auto pt-28">
        <div className="max-w-2xl lg:max-w-5xl mx-auto">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
              Contact us
            </h1>
            <p className="mt-1 text-gray-600 dark:text-gray-400">
              We'd love to talk about how we can help you.
            </p>
          </div>

          <div className="mt-12 grid items-center lg:grid-cols-2 gap-6 lg:gap-16">
            <div className="divide-y divide-gray-200 dark:divide-gray-800 ">
              <Card
                title="Location"
                details="Pretoria, South Africa"
                icon={<MapPinned size={24} strokeWidth={1.75} />}
              />

              <Card
                title="Email"
                details="info@silversixpence.io"
                icon={<Mail size={24} strokeWidth={1.75} />}
              />
            </div>

            <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8 dark:border-gray-700 ">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
