import * as z from "zod";

export const ProfileSchema = z.object({
  ProfileID: z
    .string()
    .max(255)
    .min(1, { message: "Workspace ID is required" }),
  OrgID: z.string().max(255).min(1, { message: "Org ID is required" }),
  UserID: z.string().max(255).min(1, { message: "User ID is required" }),
  ProfileName: z
    .string()
    .max(255)
    .min(1, { message: "Profile Name is required" }),
  Updated: z.string().max(255).min(1, { message: "Updated is required" }),
});

export type ProfileType = z.infer<typeof ProfileSchema>;

export const CreateWorkspaceSchema = z.object({
  workspaceName: z.string().max(255).min(1, { message: "Name is required" }),
  currency: z.string().max(255).min(1, { message: "Currency is required" }),
});

export type CreateWorkspaceType = z.infer<typeof CreateWorkspaceSchema>;
