import axios from "axios";
import { User } from "../types/auth.type";

const apiService = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

// Add a request interceptor
apiService.interceptors.request.use(
  (config) => {
    const user: User | null = JSON.parse(
      sessionStorage.getItem("user") || "null"
    );
    const token = user?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiService;
