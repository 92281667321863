import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from "@/components/ui/carousel";
import { values } from "@/config/data";
import Lottie from "lottie-react";
import * as React from "react";

export function CarouselSection() {
  const [api, setApi] = React.useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <section className="w-full max-h-screen bg-gray-800 dark:bg-gray-950 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]">
      <div className="max-w-7xl px-8 py-28 sm:px-12 lg:px-16 lg:py-28 mx-auto">
        <div className="text-center md:text-left mb-12"></div>

        <Carousel setApi={setApi} className="w-full">
          <CarouselContent>
            {values.map((value, index) => (
              <CarouselItem key={index}>
                <Card className="bg-transparent border-none shadow-none">
                  <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                    <div className="text-center md:text-left">
                      <div className="mt-8 text-4xl font-extrabold text-white">
                        <span>{value.title}</span>
                      </div>
                      <div className="mt-8 text-white">
                        <span>{value.info}</span>
                      </div>
                    </div>
                    <div className="flex items-center justify-center mt-8 md:mt-0">
                      <Lottie
                        animationData={value.img}
                        autoplay
                        className="w-full max-w-lg"
                      />
                    </div>
                  </CardContent>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="text-white" />
          <CarouselNext className="text-white" />
        </Carousel>

        <div className="py-2 text-center text-sm text-muted-foreground">
          {current} of {count}
        </div>
      </div>
    </section>
  );
}
