import { AUTH_DELETE_ACCOUNT_BUTTON } from "@/config/auth";
import { Button } from "@/components/ui/button";
import { LOADING_TEXT } from "@/config/general";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { useToast } from "../ui/use-toast";

export const DeleteAccountForm = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { toast } = useToast();

  // @ts-ignore TODO: Implement
  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      // Call the endpoint to change the password
      // Example: await api.deleteAccount(data);
      toast({
        title: "Success",
        description: "Account deleted successfully.",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to deleted account.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-center mt-4">
      <p className="text-sm text-gray-600">
        We're sorry to see you go. Deleting your account is permanent and
        deletes your user information.
      </p>
      {loading ? (
        <Button disabled variant="destructive_outline" className="mt-4">
          <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          {LOADING_TEXT}
        </Button>
      ) : (
        <Button
          disabled
          onClick={handleDeleteAccount}
          className="mt-4"
          variant="destructive_outline"
        >
          {AUTH_DELETE_ACCOUNT_BUTTON}
        </Button>
      )}
      <p className="text-sm mt-4">
        Contact us at{" "}
        <a
          href="mailto:info@silversixpence.io"
          className="text-blue-600 hover:text-blue-800"
        >
          info@silversixpence.io
        </a>{" "}
        if there is anything we can do to help.
      </p>
    </div>
  );
};
