import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useState } from "react";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Input } from "./input";

interface DatePickerProps {
  date: Date;
  setDate: (date: Date) => void;
  maxDate?: Date; // Optional max date
  includeTime?: boolean;
}

export function DatePicker({
  date = new Date(),
  setDate = () => {},
  maxDate,
  includeTime = false,
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP HH:mm") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className=" w-auto p-0">
        <Calendar
          mode="single"
          captionLayout="dropdown-buttons"
          selected={date}
          onSelect={(date) => {
            setDate(date || new Date());
            !includeTime && setIsOpen(false);
          }}
          initialFocus
          toDate={maxDate}
          fromYear={2010}
        />
        {includeTime && (
          <div className="p-4">
            <Input
              type="time"
              value={format(date, "HH:mm")}
              onChange={(e) => {
                const time = e.target.value;
                const [hours, minutes] = time.split(":");
                const newDate = new Date(date);
                newDate.setHours(Number(hours));
                newDate.setMinutes(Number(minutes));
                setDate(newDate);
              }}
              className="bg-white dark:bg-gray-800 text-black dark:text-white border dark:border-gray-700"
            />
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}
