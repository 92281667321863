import * as React from "react";

import {
  CREATE_WORKSPACE_FORM_DESCRIPTION,
  CREATE_WORKSPACE_FORM_HEADER,
} from "@/config/forms";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import { CreateWorkspaceForm } from "../forms/CreateWorkspaceForm";
import { FC } from "react";
import { Separator } from "@/components/ui/separator";

interface WorkspaceSwitcherModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children: React.ReactNode;
}

export const WorkspaceSwitcherModal: FC<WorkspaceSwitcherModalProps> = ({
  isOpen,
  setIsOpen,
  children,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {children}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{CREATE_WORKSPACE_FORM_HEADER}</DialogTitle>
          <DialogDescription>
            {CREATE_WORKSPACE_FORM_DESCRIPTION}
          </DialogDescription>
        </DialogHeader>
        <Separator />
        <CreateWorkspaceForm setModal={setIsOpen} />
      </DialogContent>
    </Dialog>
  );
};
