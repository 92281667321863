import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Check, Clipboard } from "lucide-react";
import * as React from "react";

interface AddressCellProps {
  fullAddress: string;
}

const AddressCell: React.FC<AddressCellProps> = ({ fullAddress }) => {
  const truncatedAddress = `${fullAddress.slice(0, 6)}...${fullAddress.slice(-5)}`;
  const [isOpen, setIsOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen(true);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div
          className="flex items-center space-x-1 cursor-pointer text-blue-500"
          onContextMenu={handleContextMenu}
        >
          <span>{truncatedAddress}</span>
          {copied ? (
            <Check className="h-4 w-4 text-green-500" />
          ) : (
            <Clipboard
              className="h-4 w-4 text-gray-700 dark:text-gray-400"
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(fullAddress);
              }}
            />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-82 p-4">
        <div className="flex flex-col space-y-2">
          <span className="break-words text-sm text-muted-foreground">
            {fullAddress}
          </span>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default AddressCell;
