import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { UpdateProfileSchema, UpdateProfileType } from "@/zod/auth.zod";

import { AUTH_UPDATE_PROFILE_BUTTON } from "@/config/auth";
import { AlertDestructive } from "@/components/alerts/AlertDestructive";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { LOADING_TEXT } from "@/config/general";
import { ReloadIcon } from "@radix-ui/react-icons";
import useAuthStore from "@/stores/auth.store";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useToast } from "../ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { editUser } from "@/services/userService";

export const UpdateProfileForm = () => {
  const [user, refreshUser] = useAuthStore((state) => [
    state.user,
    state.refreshUser,
  ]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { toast } = useToast();

  const updateProfileForm = useForm<UpdateProfileType>({
    resolver: zodResolver(UpdateProfileSchema),
    defaultValues: {
      email: user?.email || "",
      username: user?.name || "",
    },
  });

  const onSubmit = async (data: UpdateProfileType) => {
    try {
      setLoading(true);

      const payload: any = {
        email: user?.email,
        name: data.username,
        emailchange: data.email,
      };
      const response: any = await editUser(payload);

      await refreshUser();

      toast({
        title: "Success",
        description:
          response.message || "User information updated successfully.",
      });
    } catch (response: any) {
      setError(response?.data?.errors[0]);

      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update user information.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...updateProfileForm}>
      <div className="mt-4">
        {error ? <AlertDestructive title="Error" description={error} /> : <></>}
      </div>
      <form
        onSubmit={updateProfileForm.handleSubmit(onSubmit)}
        className="space-y-8"
      >
        <FormField
          control={updateProfileForm.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Enter your new Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={updateProfileForm.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input placeholder="Enter your new username" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {loading ? (
          <Button disabled size="lg">
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
            {LOADING_TEXT}
          </Button>
        ) : (
          <Button type="submit" size="lg">
            {AUTH_UPDATE_PROFILE_BUTTON}
          </Button>
        )}
      </form>
    </Form>
  );
};
