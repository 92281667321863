import { AUTH_LOGIN_DESCRIPTION, AUTH_LOGIN_HEADER } from "@/config/auth";
import useAuthStore from "@/stores/auth.store";
import { animated, useSpring } from "@react-spring/web";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthCard } from "../components/cards/AuthCard";
import { LoginForm } from "../components/forms/LoginForm";

function Auth() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);

  const animationProps = useSpring({
    from: { opacity: 0, transform: "scale(0.9) translateY(20px)" },
    to: { opacity: 1, transform: "scale(1) translateY(0px)" },
    config: { tension: 220, friction: 18 },
  });

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <animated.div style={animationProps}>
      <AuthCard header={AUTH_LOGIN_HEADER} description={AUTH_LOGIN_DESCRIPTION}>
        <LoginForm />
      </AuthCard>
    </animated.div>
  );
}

export default Auth;
