import { FC, useState, useEffect, useMemo } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { AlertDestructive } from "@/components/alerts/AlertDestructive";
import { Button } from "@/components/ui/button";
import { CREATE_WORKSPACE_FORM_BUTTON } from "@/config/forms";
import { Input } from "@/components/ui/input";
import { LOADING_TEXT } from "@/config/general";
import { ReloadIcon } from "@radix-ui/react-icons";
import { createWorkspace, updateWorkspace } from "@/services/workspaceService";
import useAuthStore from "@/stores/auth.store";
import { useForm } from "react-hook-form";
import useWorkspaceStore from "@/stores/workspaces.store";
import { useToast } from "../ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import useSourcesStore from "@/stores/sources.store";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import {
  CreateWorkspaceSchema,
  CreateWorkspaceType,
} from "@/zod/workspace.zod";

interface CreateWorkspaceFormProps {
  setModal: (loading: boolean) => void;
  workspaceId?: string;
}

export const CreateWorkspaceForm: FC<CreateWorkspaceFormProps> = ({
  setModal,
  workspaceId,
}) => {
  const email = useAuthStore((state) => state.user?.email);
  const [workspaces, setSelectedWorkspaceStore, fetchWorkspaces] =
    useWorkspaceStore((state) => [
      state.workspaces,
      state.setSelectedWorkspace,
      state.fetchWorkspaces,
    ]);
  const networks = useSourcesStore.getState().networks;
  const availableCurrencies = networks.CURRENCIES || [];

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { toast } = useToast();

  const doesWorkspaceNameExist = (workspaceName: string): boolean => {
    return workspaces.some(
      (workspace) =>
        workspace.workspaceName.trim().toLowerCase() ===
        workspaceName.trim().toLowerCase()
    );
  };

  const getInitialValues = useMemo(() => {
    if (workspaceId) {
      const workspace = workspaces.find((w) => w.workspaceId === workspaceId);
      if (workspace) {
        return {
          workspaceName: workspace.workspaceName,
          currency: workspace.currency,
        };
      }
    }
    return {
      workspaceName: "",
      currency: "USD",
    };
  }, [workspaceId, workspaces]);

  const createWorkspaceForm = useForm<CreateWorkspaceType>({
    resolver: zodResolver(CreateWorkspaceSchema),
    defaultValues: getInitialValues,
  });

  useEffect(() => {
    if (workspaceId) {
      const workspace = workspaces.find((w) => w.workspaceId === workspaceId);
      if (workspace) {
        createWorkspaceForm.reset({
          workspaceName: workspace.workspaceName,
          currency: workspace.currency,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  if (!email) {
    return null;
  }

  const onSubmit = async (data: CreateWorkspaceType) => {
    setLoading(true);

    if (!workspaceId && doesWorkspaceNameExist(data.workspaceName)) {
      setError("A workspace with that name already exists.");
      setLoading(false);
      return;
    }

    try {
      if (workspaceId) {
        await updateWorkspace(workspaceId, data);
        toast({
          title: "Success",
          description: `Successfully updated ${data.workspaceName} workspace!`,
        });
      } else {
        // Create new workspace
        const insertResponse: any = await createWorkspace(
          data.workspaceName,
          data.currency
        );
        await fetchWorkspaces();
        await setSelectedWorkspaceStore(insertResponse.data.workspaceId);
        toast({
          title: "Success",
          description: `Successfully created ${data.workspaceName} workspace!`,
        });
      }
      setModal(false);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: workspaceId
          ? "Failed to update the workspace."
          : "Failed to create a new workspace.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...createWorkspaceForm}>
      <div>
        {error ? <AlertDestructive title="Error" description={error} /> : <></>}
      </div>
      <form
        onSubmit={createWorkspaceForm.handleSubmit(onSubmit)}
        className="space-y-8"
      >
        <FormField
          control={createWorkspaceForm.control}
          name="workspaceName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Workspace Name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter workspace name. Eg. Luno..."
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Currency Dropdown */}
        <FormField
          control={createWorkspaceForm.control}
          name="currency"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Currency</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {availableCurrencies.map((currency: any) => (
                    <SelectItem value={currency.symbol} key={currency.symbol}>
                      <img
                        src={currency.icon}
                        alt={currency.name}
                        className="inline-block mr-2 h-4 w-4"
                      />
                      {currency.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        {loading ? (
          <Button disabled className="w-full" size="lg">
            <ReloadIcon className="animate-spin" />
            {LOADING_TEXT}
          </Button>
        ) : (
          <Button type="submit" className="w-full" size="lg">
            {CREATE_WORKSPACE_FORM_BUTTON}
          </Button>
        )}
      </form>
    </Form>
  );
};
