import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useAuthStore from "@/stores/auth.store";
import { LogOut, UserCog } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

const brightColors = ["#000", "#0e1111", "#232b2b"];

const getInitials = (name: string) => {
  const nameArray = name.split(" ");
  const initials =
    nameArray.length > 1
      ? `${nameArray[0].charAt(0)}${nameArray[nameArray.length - 1].charAt(0)}`
      : nameArray[0].charAt(0);
  return initials.toUpperCase();
};

export function AvatarMenu() {
  const navigate = useNavigate();
  const [user, logout] = useAuthStore((state) => [state.user, state.logout]);

  const [randomColor, setRandomColor] = useState(brightColors[0]);

  useEffect(() => {
    const color = brightColors[Math.floor(Math.random() * brightColors.length)];
    setRandomColor(color);
  }, []);

  const initials = user ? getInitials(user.name) : "";

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar>
          <AvatarImage src="" alt="" />
          <AvatarFallback
            style={{ backgroundColor: randomColor }}
            className="text-xl font-bold text-white"
          >
            {initials}
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-64 mr-8 text-center py-4 dark:border-gray-800">
        <div className="flex justify-center mb-4">
          <Avatar className="h-16 w-16">
            <AvatarImage src="" alt="" />
            <AvatarFallback
              style={{ backgroundColor: randomColor }}
              className="text-2xl font-bold text-white"
            >
              {initials}
            </AvatarFallback>
          </Avatar>
        </div>
        <h2 className="mb-4">Hi, {user?.name}!</h2>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              navigate("/account");
            }}
            className="border rounded-full my-2"
          >
            <UserCog className="mr-2 h-4 w-4" />
            <span>Manage your account</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            logout();
          }}
          className="my-2"
        >
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
